import { defineComponent, onMounted } from "vue";
// import { dom } from "@fortawesome/fontawesome-svg-core";
export default defineComponent({
    name: "Footer",
    setup: function () {
        onMounted(function () {
            // dom.watch();
        });
    }
});
