import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import router from "@/router/index";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTMobilePageTitle from "@/layout/toolbar/MobilePageTitle.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
// import KTLoader from "@/components/Loader.vue";
// import KTExplore from "@/layout/extras/Explore.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { toolbarDisplay, loaderEnabled, contentWidthFluid, loaderLogo, asideEnabled, subheaderDisplay, themeLightLogo, themeDarkLogo } from "@/core/helpers/config";
import { isDocPage } from "@/core/helpers/documentation";
export default defineComponent({
    name: "Layout",
    components: {
        KTAside: KTAside,
        KTHeader: KTHeader,
        KTFooter: KTFooter,
        KTToolbar: KTToolbar,
        KTScrollTop: KTScrollTop,
        // KTExplore,
        // KTLoader,
        KTMobilePageTitle: KTMobilePageTitle
    },
    setup: function () {
        var store = useStore();
        var route = useRoute();
        // show page loading
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        // initialize html element classes
        HtmlClass.init();
        var pageTitle = computed(function () {
            return store.getters.pageTitle;
        });
        var breadcrumbs = computed(function () {
            return store.getters.pageBreadcrumbPath;
        });
        onMounted(function () {
            // // check if current user is authenticated
            // if (store.getters.isAuthenticated) {
            //   router.push({ name: "sign-in" });
            // }
            // Simulate the delay page loading
            setTimeout(function () {
                // Remove page loader after some time
                store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
            }, 500);
        });
        watch(function () { return route.path; }, function () {
            MenuComponent.hideDropdowns(undefined);
            // // check if current user is authenticated
            // if (store.getters.isAuthenticated) {
            //   router.push({ name: "sign-in" });
            // }
            removeModalBackdrop();
        });
        return {
            toolbarDisplay: toolbarDisplay,
            loaderEnabled: loaderEnabled,
            contentWidthFluid: contentWidthFluid,
            loaderLogo: loaderLogo,
            asideEnabled: asideEnabled,
            subheaderDisplay: subheaderDisplay,
            pageTitle: pageTitle,
            breadcrumbs: breadcrumbs,
            isDocPage: isDocPage,
            themeLightLogo: themeLightLogo,
            themeDarkLogo: themeDarkLogo,
            router: router
        };
    }
});
