import { defineComponent } from "vue";
export default defineComponent({
    name: "KToolbar",
    props: {
        breadcrumbs: Array,
        title: String
    },
    methods: {
        searchBlock: function ($event) {
            $event.preventDefault();
            var KEY_ENTER = 13;
            var HASH_LENGTH = 64;
            if ($event.keyCode === KEY_ENTER || $event.type === "click") {
                // const textEl = document.getElementById("search") as HTMLInputElement;
                // const text = textEl.value;
                var textEl = document.getElementById("search-input");
                var text = textEl.value;
                if (text != "" && text.length == HASH_LENGTH) {
                    this.$router.push({
                        name: "blockInfo",
                        params: { hash: text }
                    });
                }
            }
        }
    }
});
