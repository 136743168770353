var DocMenuConfig = [
    {
        heading: "Main Menu",
        pages: [
            {
                heading: "Overview",
                route: "/latest",
                svgIcon: "media/icons/PenAndRuller.svg",
                fontIcon: "bi-app-indicator",
            },
            {
                heading: "Blocks",
                route: "/blocks",
                svgIcon: "media/icons/Puzzle.svg",
                fontIcon: "bi-app-indicator",
            },
            {
                heading: "Status",
                route: "/status",
                svgIcon: "media/icons/Clipboard.svg",
                fontIcon: "bi-app-indicator",
            }
        ]
    },
    {
        heading: "More Info",
        pages: [
            {
                heading: "Chart",
                route: "/stats",
                svgIcon: "media/icons/Equalizer.svg",
                fontIcon: "bi-app-indicator",
            },
        ],
    },
];
export default DocMenuConfig;
