import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { isDocPage, version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import DocMenuConfig from "@/core/config/DocMenuConfig";
import MainMenuConfig from "@/core/config/CwChainMenu";
export default defineComponent({
    name: "kt-menu",
    components: {},
    setup: function () {
        var _a = useI18n(), t = _a.t, te = _a.te;
        var route = useRoute();
        var scrollElRef = ref(null);
        onMounted(function () {
            ScrollComponent.reinitialization();
            MenuComponent.reinitialization();
            if (scrollElRef.value) {
                scrollElRef.value.scrollTop = 0;
            }
        });
        var translate = function (text) {
            if (te(text)) {
                return t(text);
            }
            else {
                return text;
            }
        };
        var hasActiveChildren = function (match) {
            return route.path.indexOf(match) !== -1;
        };
        return {
            hasActiveChildren: hasActiveChildren,
            isDocPage: isDocPage,
            DocMenuConfig: DocMenuConfig,
            MainMenuConfig: MainMenuConfig,
            asideMenuIcons: asideMenuIcons,
            version: version,
            translate: translate
        };
    }
});
