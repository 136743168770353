<template>
  <!--begin::Toolbar-->
  <div class="toolbar" id="kt_toolbar">
    <!--begin::Container-->
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div
        class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1"
      >
        <!--begin::Title-->
        <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
          {{ title }}
        </h1>
        <!--end::Title-->

        <span
          v-if="breadcrumbs"
          class="h-20px border-gray-200 border-start mx-4"
        ></span>

        <!--begin::Breadcrumb-->
        <ul
          v-if="breadcrumbs"
          class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
        >
          <li class="breadcrumb-item pe-3">
            <router-link to="/latest" class="text-muted text-hover-primary">
              Latest Blocks
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
          </li>
          <template v-for="(item, index) in breadcrumbs" :key="index">
            <li class="breadcrumb-item text-muted">
              {{ item }}
            </li>
            <li class="breadcrumb-item">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
            </li>
          </template>
          <li class="breadcrumb-item pe-3 text-dark">
            {{ title }}
          </li>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->

      <!--begin::Actions-->
      <!-- <div class="d-flex align-items-center py-1"> -->
      <!--begin::Wrapper-->
      <!--begin::Search -->
      <!-- <div class="input-group rounded me-4">
          <input
            type="search"
            class="form-control rounded"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-addon"
          />
          <span class="input-group-text border-0 " id="search-addon">
            <i class="fas fa-search"></i>
          </span>
        </div> -->

      <!--begin::Search-->
      <!-- <div class="d-flex align-items-center py-1"> -->
      <!--begin::Wrapper-->
      <!-- <div class="me-4">
          <form
            action="https://cwchain.org/search"
            class="d-flex align-items-center"
            method="post"
          >
            <input
              type="hidden"
              name="_token"
              value="t09S8s8JVYoo3J6hy77IkhfgFyrL5gPGxkJvHhnr"
            />
            <div class="position-relative w-md-800px me-md-2"> -->
      <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
      <!-- <span
                class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    ></path>
                    <path
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </svg>
              </span> -->
      <!--end::Svg Icon-->
      <!-- <input
                type="text"
                class="form-control form-control-solid ps-10"
                name="search"
                value=""
                placeholder="Search"
              />
            </div> -->
      <!--end::Input group-->
      <!--begin:Action-->
      <!-- <div class="d-flex align-items-center">
              <button type="submit" class="btn btn-primary me-5">Search</button>
            </div> -->
      <!--end:Action-->
      <!-- </form>
        </div> -->
      <!--end::Wrapper-->
      <!--begin::Button-->
      <!-- <a href="/blocks" class="btn btn-sm btn-primary d-lg-none">Blocks</a> -->
      <!--end::Button-->
      <!-- </div> -->

      <!-- <div class="col container w-md-800px"> -->

      <div class="d-flex align-items-center py-1">
        <!--begin::Wrapper-->
        <div class="me-4">
          <form
            class="d-flex align-items-center"
            method="get"
            @submit="searchBlock"
          >
            <div class="position-relative w-md-800px me-md-2">
              <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
              <span
                class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    ></path>
                    <path
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </svg>
              </span>
              <!--end::Svg Icon-->
              <input
                id="search-input"
                type="text"
                class="form-control form-control-solid ps-10"
                name="search"
                value=""
                placeholder="Search"
                @keypress="searchBlock"
              />
            </div>
            <!--end::Input group-->
            <!--begin:Action-->
            <div class="d-flex align-items-center">
              <button
                type="submit"
                class="btn btn-primary me-5"
                @click="searchBlock"
              >
                Search
              </button>
            </div>
            <!--end:Action-->
          </form>
        </div>
        <!--end::Wrapper-->
        <!--begin::Button-->
        <!-- <a href="/blocks" class="btn btn-sm btn-primary d-lg-none">Blocks</a> -->
        <!--end::Button-->
      </div>

      <!-- <input
          id="search"
          type="text"
          class="form-control form-control-solid ps-10"
          placeholder="Block hash"
          @keypress="searchBlock"
        /> -->
      <!-- <button class="btn btn-info" @click="searchBlock">
        <i class="fa fa-search"></i>
      </button> -->
      <!--</div>-->
      <!--end::Search-->

      <!--end::Wrapper-->
      <!-- </div> -->
      <!--end::Actions-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Toolbar-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String
  },
  methods: {
    searchBlock($event) {
      $event.preventDefault();
      const KEY_ENTER = 13;
      const HASH_LENGTH = 64;

      if ($event.keyCode === KEY_ENTER || $event.type === "click") {
        // const textEl = document.getElementById("search") as HTMLInputElement;
        // const text = textEl.value;
        const textEl = document.getElementById(
          "search-input"
        ) as HTMLInputElement;
        const text = textEl.value;

        if (text != "" && text.length == HASH_LENGTH) {
          this.$router.push({
            name: "blockInfo",
            params: { hash: text }
          });
        }
      }
    }
  }
});
</script>
