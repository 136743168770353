<template>
  <!-- Footer -->
  <footer class="text-center text-lg-start text-white mb-0">
    <!-- Grid container -->
    <div class="container p-4 pb-0">
      <!-- Section: Links -->
      <section class="">
        <!--Grid row-->
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 class="text-uppercase mb-4 font-weight-bold">
              <a href="#">
                <img
                  src="@/assets/media/logos/148x43_whiteupanddown.png"
                  alt="The #1 Copyright Protection Service | CopyrightsWorld"
                  class="d-inline-block align-text-top"
                />
              </a>
            </h6>
            <p>The #1 Copyright Protection Service | CopyrightsWorld</p>
          </div>
          <!-- Grid column -->

          <hr class="w-100 clearfix d-md-none" />

          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
            <!-- <h6 class="text-uppercase mb-4 font-weight-bold">Resources</h6>
            <p>
              <a class="text-white">How It Works</a>
            </p>
            <p>
              <a class="text-white">Our Technology</a>
            </p>
            <p>
              <a class="text-white">White Paper</a>
            </p>
            <p>
              <a class="text-white">For Business / Teams</a>
            </p>
            <p>
              <a class="text-white">Knowledge Base</a>
            </p>
            <p>
              <a class="text-white">System Status</a>
            </p>
            <p>
              <a class="text-white">Become Our Partner</a>
            </p>
            <p>
              <a class="text-white">Contact Us</a>
            </p> -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <hr class="w-100 clearfix d-md-none" />

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 class="text-uppercase mb-4 font-weight-bold">Contact us</h6>
            <p>
              <i class="fas fa-home mr-3"></i> 8 The Green, STE A, Dover,
              Delaware, 19901, United States
            </p>
            <p>
              <i class="fas fa-envelope mr-3"></i> support@copyrightsworld.com
            </p>
            <p><i class="fas fa-phone mr-3"></i> +1 650 614 1751</p>
          </div>
          <!-- Grid column -->
        </div>
        <!--Grid row-->
      </section>
      <!-- Section: Links -->

      <hr class="my-3" />

      <!-- Section: Copyright -->
      <section class="p-3 pt-0">
        <div class="row d-flex align-items-center">
          <!-- Grid column -->
          <div class="col-md-7 col-lg-8 text-center text-md-start">
            <!-- Copyright -->
            <div class="p-3">
              2021&copy;
              <a
                class="text-white text-hover-primary"
                href="https://copyrightsworld.com/"
                target="_blank"
                >CopyrightsWorld</a
              >
              , All rights reserved.
            </div>
            <!-- Copyright -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
            <!-- Facebook -->
            <a
              class="btn btn-outline-light btn-floating m-1 text-white"
              role="button"
              href="https://www.facebook.com"
              target="_blank"
            >
              <i class="fab fa-facebook-f"></i>
            </a>

            <!-- Twitter -->
            <a
              class="btn btn-outline-light btn-floating m-1 text-white"
              role="button"
              href="https://twitter.com"
              target="_blank"
            >
              <i class="fab fa-twitter"></i>
            </a>

            <!-- Google -->
            <a
              class="btn btn-outline-light btn-floating m-1 text-white"
              role="button"
              href="https://google.com"
              target="_blank"
            >
              <i class="fab fa-google"></i>
            </a>

            <!-- Instagram -->
            <a
              class="btn btn-outline-light btn-floating m-1 text-white"
              role="button"
              href="https://instagram.com"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
          <!-- Grid column -->
        </div>
      </section>
      <!-- Section: Copyright -->
    </div>
    <!-- Grid container -->
  </footer>
  <!-- Footer -->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
// import { dom } from "@fortawesome/fontawesome-svg-core";

export default defineComponent({
  name: "Footer",
  setup() {
    onMounted(() => {
      // dom.watch();
    });
  }
});
</script>

<style>
footer {
  background-color: #131628;
}
</style>
