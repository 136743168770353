import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import { isDocPage } from "@/core/helpers/documentation";
import { asideTheme } from "@/core/helpers/config";
export default defineComponent({
    name: "KTAside",
    components: {
        KTMenu: KTMenu
    },
    props: {
        lightLogo: String,
        darkLogo: String
    },
    setup: function () {
        var t = useI18n().t;
        onMounted(function () {
            DrawerComponent.reinitialization();
            ToggleComponent.reinitialization();
        });
        onUpdated(function () {
            ToggleComponent.bootstrap();
        });
        return {
            isDocPage: isDocPage,
            asideTheme: asideTheme,
            t: t
        };
    }
});
