import { defineComponent } from "vue";
export default defineComponent({
    name: "topbar",
    components: {},
    methods: {
        fetchStats: function () {
            this.$router.push("stats");
        }
    }
});
