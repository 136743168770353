var DocMenuConfig = [
    {
        heading: "Overview",
        route: "/latest",
        name: "latest"
    },
    {
        heading: "Blocks",
        route: "/blocks",
        name: "blocks"
    },
    {
        heading: "Status",
        route: "/status",
        name: "status"
    }
];
export default DocMenuConfig;
