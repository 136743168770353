import { defineComponent, onMounted } from "vue";
import { ScrollTopComponent } from "@/assets/ts/components/_ScrollTopComponent";
export default defineComponent({
    name: "KTScrollTop",
    components: {},
    setup: function () {
        onMounted(function () {
            ScrollTopComponent.reinitialization();
        });
    }
});
