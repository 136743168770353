var DocMenuConfig = [
    {
        heading: "Getting Started",
        pages: [
            {
                heading: "Overview",
                route: "/documentation/doc-overview"
            },
            {
                heading: "Build",
                route: "/documentation/build"
            },
            {
                heading: "Setup theme skeleton",
                route: "/documentation/setup-theme-skeleton"
            },
            {
                heading: "Layout Builder",
                route: "/builder"
            },
            {
                heading: "Updates",
                route: "/documentation/updates"
            },
            {
                heading: "Changelog",
                route: "/documentation/changelog"
            }
        ]
    },
    {
        heading: "Base",
        pages: [
            {
                heading: "Utilities",
                route: "/documentation/utilities"
            },
            {
                sectionTitle: "Helpers",
                route: "/helpers",
                sub: [
                    {
                        heading: "Flex Layots",
                        route: "/documentation/helpers/flex-layouts"
                    },
                    {
                        heading: "Text",
                        route: "/documentation/helpers/text"
                    },
                    {
                        heading: "Background",
                        route: "/documentation/helpers/background"
                    },
                    {
                        heading: "Borders",
                        route: "/documentation/helpers/borders"
                    }
                ]
            },
            {
                heading: "Forms",
                route: "/documentation/forms"
            },
            {
                heading: "Buttons",
                route: "/documentation/buttons"
            },
            {
                heading: "Indicator",
                route: "/documentation/indicator"
            },
            {
                heading: "Rotate",
                route: "/documentation/rotate"
            },
            {
                heading: "Tables",
                route: "/documentation/tables"
            },
            {
                heading: "Cards",
                route: "/documentation/cards"
            },
            {
                heading: "Symbol",
                route: "/documentation/symbol"
            },
            {
                heading: "Badges",
                route: "/documentation/badges"
            },
            {
                heading: "Pulse",
                route: "/documentation/pulse"
            },
            {
                heading: "Bullets",
                route: "/documentation/bullets"
            },
            {
                heading: "Accordion",
                route: "/documentation/accordion"
            },
            {
                heading: "Carousel",
                route: "/documentation/carousel"
            },
            {
                heading: "Overlay",
                route: "/documentation/overlay"
            },
            {
                heading: "Separator",
                route: "/documentation/separator"
            },
            {
                heading: "Tabs",
                route: "/documentation/tabs"
            },
            {
                heading: "Breadcrumb",
                route: "/documentation/breadcrumb"
            },
            {
                heading: "Modal",
                route: "/documentation/modal"
            },
            {
                heading: "Pagination",
                route: "/documentation/pagination"
            }
        ]
    },
    {
        heading: "Forms",
        pages: [
            {
                heading: "Vue Multiselect",
                route: "/documentation/vue-select"
            },
            {
                heading: "VeeValidate",
                route: "/documentation/vee-validate"
            }
        ]
    },
    {
        heading: "General",
        pages: [
            {
                sectionTitle: "Icons",
                route: "/icons",
                sub: [
                    {
                        heading: "Duotone",
                        route: "/documentation/icons/duotone"
                    },
                    {
                        heading: "Bootstrap Icons",
                        route: "/documentation/icons/bootstrap-icons"
                    },
                    {
                        heading: "Font Awesome",
                        route: "/documentation/icons/font-awesome"
                    },
                    {
                        heading: "Line Awesome",
                        route: "/documentation/icons/line-awesome"
                    }
                ]
            }
        ]
    },
    {
        heading: "Element UI",
        pages: [
            {
                sectionTitle: "Basic",
                route: "/basic",
                sub: [
                    {
                        heading: "Layout",
                        route: "/documentation/element-ui/basic/layout"
                    },
                    {
                        heading: "Layout Container",
                        route: "/documentation/element-ui/basic/layout-container"
                    },
                    {
                        heading: "Icon",
                        route: "/documentation/element-ui/basic/icon"
                    },
                    {
                        heading: "Button",
                        route: "/documentation/element-ui/basic/button"
                    },
                    {
                        heading: "Link",
                        route: "/documentation/element-ui/basic/link"
                    },
                    {
                        heading: "Space",
                        route: "/documentation/element-ui/basic/space"
                    }
                ]
            },
            {
                sectionTitle: "Form",
                route: "/form",
                sub: [
                    {
                        heading: "Radio",
                        route: "/documentation/element-ui/form/radio"
                    },
                    {
                        heading: "Checkbox",
                        route: "/documentation/element-ui/form/checkbox"
                    },
                    {
                        heading: "Input",
                        route: "/documentation/element-ui/form/input"
                    },
                    {
                        heading: "InputNumber",
                        route: "/documentation/element-ui/form/input-number"
                    },
                    {
                        heading: "Select",
                        route: "/documentation/element-ui/form/select"
                    },
                    {
                        heading: "Cascader",
                        route: "/documentation/element-ui/form/cascader"
                    },
                    {
                        heading: "Switch",
                        route: "/documentation/element-ui/form/switch"
                    },
                    {
                        heading: "Slider",
                        route: "/documentation/element-ui/form/slider"
                    },
                    {
                        heading: "TimePicker",
                        route: "/documentation/element-ui/form/time-picker"
                    },
                    {
                        heading: "TimeSelect",
                        route: "/documentation/element-ui/form/time-select"
                    },
                    {
                        heading: "DatePicker",
                        route: "/documentation/element-ui/form/date-picker"
                    },
                    {
                        heading: "DateTimePicker",
                        route: "/documentation/element-ui/form/date-time-picker"
                    },
                    {
                        heading: "Upload",
                        route: "/documentation/element-ui/form/upload"
                    },
                    {
                        heading: "Rate",
                        route: "/documentation/element-ui/form/rate"
                    },
                    {
                        heading: "ColorPicker",
                        route: "/documentation/element-ui/form/color-picker"
                    },
                    {
                        heading: "Transfer",
                        route: "/documentation/element-ui/form/transfer"
                    },
                    {
                        heading: "Form",
                        route: "/documentation/element-ui/form/form"
                    }
                ]
            },
            {
                sectionTitle: "Data",
                route: "/data",
                sub: [
                    {
                        heading: "Table",
                        route: "/documentation/element-ui/data/table"
                    },
                    {
                        heading: "Tag",
                        route: "/documentation/element-ui/data/tag"
                    },
                    {
                        heading: "Progress",
                        route: "/documentation/element-ui/data/progress"
                    },
                    {
                        heading: "Tree",
                        route: "/documentation/element-ui/data/tree"
                    },
                    {
                        heading: "Pagination",
                        route: "/documentation/element-ui/data/pagination"
                    },
                    {
                        heading: "Badge",
                        route: "/documentation/element-ui/data/badge"
                    },
                    {
                        heading: "Skeleton",
                        route: "/documentation/element-ui/data/skeleton"
                    },
                    {
                        heading: "Empty",
                        route: "/documentation/element-ui/data/empty"
                    }
                ]
            },
            {
                sectionTitle: "Notice",
                route: "/notice",
                sub: [
                    {
                        heading: "Alert",
                        route: "/documentation/element-ui/notice/alert"
                    },
                    {
                        heading: "Loading",
                        route: "/documentation/element-ui/notice/loading"
                    },
                    {
                        heading: "Message",
                        route: "/documentation/element-ui/notice/message"
                    },
                    {
                        heading: "MessageBox",
                        route: "/documentation/element-ui/notice/message-box"
                    },
                    {
                        heading: "Notification",
                        route: "/documentation/element-ui/notice/notification"
                    }
                ]
            },
            {
                sectionTitle: "Navigation",
                route: "/navigation",
                sub: [
                    {
                        heading: "Affix",
                        route: "/documentation/element-ui/navigation/affix"
                    },
                    {
                        heading: "NavMenu",
                        route: "/documentation/element-ui/navigation/nav-menu"
                    },
                    {
                        heading: "Tabs",
                        route: "/documentation/element-ui/navigation/tabs"
                    },
                    {
                        heading: "Breadcrumb",
                        route: "/documentation/element-ui/navigation/breadcrumb"
                    },
                    {
                        heading: "PageHeader",
                        route: "/documentation/element-ui/navigation/page-header"
                    },
                    {
                        heading: "Dropdown",
                        route: "/documentation/element-ui/navigation/dropdown"
                    },
                    {
                        heading: "Steps",
                        route: "/documentation/element-ui/navigation/steps"
                    }
                ]
            }
        ]
    }
];
export default DocMenuConfig;
