import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
export default defineComponent({
    name: "KTMenu",
    components: {},
    setup: function () {
        var _a = useI18n(), t = _a.t, te = _a.te;
        var route = useRoute();
        var hasActiveChildren = function (match) {
            return route.path.indexOf(match) !== -1;
        };
        var translate = function (text) {
            if (te(text)) {
                return t(text);
            }
            else {
                return text;
            }
        };
        onMounted(function () {
            MenuComponent.reinitialization();
        });
        return {
            hasActiveChildren: hasActiveChildren,
            headerMenuIcons: headerMenuIcons,
            MainMenuConfig: MainMenuConfig,
            translate: translate
        };
    }
});
